import React, { Component } from "react";
import { Link } from "react-router-dom";
import AUDIOFILE1 from "./sounds/3.1.mp3";
import AUDIOFILE2 from "./sounds/3.2.mp3";
import AUDIOFILE3 from "./sounds/3.3.mp3";
import AUDIOFILE4 from "./sounds/3.4.mp3";
import AUDIOFILE5 from "./sounds/3.5.mp3";
import AUDIOFILE6 from "./sounds/3.6.mp3";
import AUDIOFILE7 from "./sounds/3.7.mp3";
import AUDIOFILE8 from "./sounds/3.8.mp3";
import AUDIOFILE9 from "./sounds/3.9.mp3";
import AUDIOFILE10 from "./sounds/3.10.mp3";
import AUDIOFILE11 from "./sounds/3.11.mp3";
import AUDIOFILE12 from "./sounds/3.12.mp3";
import AUDIOFILE13 from "./sounds/3.13.mp3";
import AUDIOFILE14 from "./sounds/3.14.mp3";
import AUDIOFILE15 from "./sounds/3.15.mp3";
import AUDIOFILE16 from "./sounds/3.16.mp3";
import AUDIOFILE17 from "./sounds/3.17.mp3";
import AUDIOFILE18 from "./sounds/3.18.mp3";
import AUDIOFILE19 from "./sounds/3.19.mp3";
import AUDIOFILE20 from "./sounds/3.20.mp3";
import AUDIOFILE21 from "./sounds/3.21.mp3";
import AUDIOFILE22 from "./sounds/3.22.mp3";
import AUDIOFILE23 from "./sounds/3.23.mp3";
import AUDIOFILE24 from "./sounds/3.24.mp3";
import AUDIOFILE25 from "./sounds/3.25.mp3";
import AUDIOFILE26 from "./sounds/3.26.mp3";
import AUDIOFILE27 from "./sounds/3.27.mp3";
import AUDIOFILE28 from "./sounds/3.28.mp3";

class Jour3 extends Component {
  handleSound1 = () => {
    const audio = document.getElementsByClassName("audiofile1")[0];
    audio.play();
  };
  handleSound2 = () => {
    const audio = document.getElementsByClassName("audiofile2")[0];
    audio.play();
  };
  handleSound3 = () => {
    const audio = document.getElementsByClassName("audiofile3")[0];
    audio.play();
  };
  handleSound4 = () => {
    const audio = document.getElementsByClassName("audiofile4")[0];
    audio.play();
  };
  handleSound5 = () => {
    const audio = document.getElementsByClassName("audiofile5")[0];
    audio.play();
  };
  handleSound6 = () => {
    const audio = document.getElementsByClassName("audiofile6")[0];
    audio.play();
  };
  handleSound7 = () => {
    const audio = document.getElementsByClassName("audiofile7")[0];
    audio.play();
  };
  handleSound8 = () => {
    const audio = document.getElementsByClassName("audiofile8")[0];
    audio.play();
  };
  handleSound9 = () => {
    const audio = document.getElementsByClassName("audiofile9")[0];
    audio.play();
  };
  handleSound10 = () => {
    const audio = document.getElementsByClassName("audiofile10")[0];
    audio.play();
  };
  handleSound11 = () => {
    const audio = document.getElementsByClassName("audiofile11")[0];
    audio.play();
  };
  handleSound12 = () => {
    const audio = document.getElementsByClassName("audiofile12")[0];
    audio.play();
  };
  handleSound13 = () => {
    const audio = document.getElementsByClassName("audiofile13")[0];
    audio.play();
  };
  handleSound14 = () => {
    const audio = document.getElementsByClassName("audiofile14")[0];
    audio.play();
  };
  handleSound15 = () => {
    const audio = document.getElementsByClassName("audiofile15")[0];
    audio.play();
  };
  handleSound16 = () => {
    const audio = document.getElementsByClassName("audiofile16")[0];
    audio.play();
  };
  handleSound17 = () => {
    const audio = document.getElementsByClassName("audiofile17")[0];
    audio.play();
  };
  handleSound18 = () => {
    const audio = document.getElementsByClassName("audiofile18")[0];
    audio.play();
  };
  handleSound19 = () => {
    const audio = document.getElementsByClassName("audiofile19")[0];
    audio.play();
  };
  handleSound20 = () => {
    const audio = document.getElementsByClassName("audiofile20")[0];
    audio.play();
  };
  handleSound21 = () => {
    const audio = document.getElementsByClassName("audiofile21")[0];
    audio.play();
  };
  handleSound22 = () => {
    const audio = document.getElementsByClassName("audiofile22")[0];
    audio.play();
  };
  handleSound23 = () => {
    const audio = document.getElementsByClassName("audiofile23")[0];
    audio.play();
  };
  handleSound24 = () => {
    const audio = document.getElementsByClassName("audiofile24")[0];
    audio.play();
  };
  handleSound25 = () => {
    const audio = document.getElementsByClassName("audiofile25")[0];
    audio.play();
  };
  handleSound26 = () => {
    const audio = document.getElementsByClassName("audiofile26")[0];
    audio.play();
  };
  handleSound27 = () => {
    const audio = document.getElementsByClassName("audiofile27")[0];
    audio.play();
  };
  handleSound28 = () => {
    const audio = document.getElementsByClassName("audiofile28")[0];
    audio.play();
  };

  render = () => {
    let audioEl = [];
    audioEl[0] = (
      <audio className="audiofile1" controlsList="nodownload">
        <source src={AUDIOFILE1} type="audio/mp3"></source>
      </audio>
    );
    audioEl[1] = (
      <audio className="audiofile2">
        <source src={AUDIOFILE2} type="audio/mp3"></source>
      </audio>
    );
    audioEl[2] = (
      <audio className="audiofile3">
        <source src={AUDIOFILE3} type="audio/mp3"></source>
      </audio>
    );
    audioEl[3] = (
      <audio className="audiofile4">
        <source src={AUDIOFILE4} type="audio/mp3"></source>
      </audio>
    );
    audioEl[4] = (
      <audio className="audiofile5">
        <source src={AUDIOFILE5} type="audio/mp3"></source>
      </audio>
    );
    audioEl[5] = (
      <audio className="audiofile6">
        <source src={AUDIOFILE6} type="audio/mp3"></source>
      </audio>
    );
    audioEl[6] = (
      <audio className="audiofile7">
        <source src={AUDIOFILE7} type="audio/mp3"></source>
      </audio>
    );
    audioEl[7] = (
      <audio className="audiofile8">
        <source src={AUDIOFILE8} type="audio/mp3"></source>
      </audio>
    );
    audioEl[8] = (
      <audio className="audiofile9">
        <source src={AUDIOFILE9} type="audio/mp3"></source>
      </audio>
    );
    audioEl[9] = (
      <audio className="audiofile10">
        <source src={AUDIOFILE10} type="audio/mp3"></source>
      </audio>
    );
    audioEl[10] = (
      <audio className="audiofile11">
        <source src={AUDIOFILE11} type="audio/mp3"></source>
      </audio>
    );
    audioEl[11] = (
      <audio className="audiofile12">
        <source src={AUDIOFILE12} type="audio/mp3"></source>
      </audio>
    );
    audioEl[12] = (
      <audio className="audiofile13">
        <source src={AUDIOFILE13} type="audio/mp3"></source>
      </audio>
    );
    audioEl[13] = (
      <audio className="audiofile14">
        <source src={AUDIOFILE14} type="audio/mp3"></source>
      </audio>
    );
    audioEl[14] = (
      <audio className="audiofile15">
        <source src={AUDIOFILE15} type="audio/mp3"></source>
      </audio>
    );
    audioEl[15] = (
      <audio className="audiofile16">
        <source src={AUDIOFILE16} type="audio/mp3"></source>
      </audio>
    );
    audioEl[16] = (
      <audio className="audiofile17">
        <source src={AUDIOFILE17} type="audio/mp3"></source>
      </audio>
    );
    audioEl[17] = (
      <audio className="audiofile18">
        <source src={AUDIOFILE18} type="audio/mp3"></source>
      </audio>
    );
    audioEl[18] = (
      <audio className="audiofile19">
        <source src={AUDIOFILE19} type="audio/mp3"></source>
      </audio>
    );
    audioEl[19] = (
      <audio className="audiofile20">
        <source src={AUDIOFILE20} type="audio/mp3"></source>
      </audio>
    );
    audioEl[20] = (
      <audio className="audiofile21">
        <source src={AUDIOFILE21} type="audio/mp3"></source>
      </audio>
    );
    audioEl[21] = (
      <audio className="audiofile22">
        <source src={AUDIOFILE22} type="audio/mp3"></source>
      </audio>
    );
    audioEl[22] = (
      <audio className="audiofile23">
        <source src={AUDIOFILE23} type="audio/mp3"></source>
      </audio>
    );
    audioEl[23] = (
      <audio className="audiofile24">
        <source src={AUDIOFILE24} type="audio/mp3"></source>
      </audio>
    );
    audioEl[24] = (
      <audio className="audiofile25">
        <source src={AUDIOFILE25} type="audio/mp3"></source>
      </audio>
    );
    audioEl[25] = (
      <audio className="audiofile26">
        <source src={AUDIOFILE26} type="audio/mp3"></source>
      </audio>
    );
    audioEl[26] = (
      <audio className="audiofile27">
        <source src={AUDIOFILE27} type="audio/mp3"></source>
      </audio>
    );
    audioEl[27] = (
      <audio className="audiofile28">
        <source src={AUDIOFILE28} type="audio/mp3"></source>
      </audio>
    );
    return (
      <>
        <Link to={"/"} style={{ textDecoration: "none" }}>
          <img className="x" alt="x" src={require("./images/x.svg").default} />
        </Link>

        <div className="jour1-container">
          <div className="lyrics-container">
            <p className="lyrics">
              <span onClick={this.handleSound1}>I'm</span>
              <span onClick={this.handleSound2}> about,</span>
              <span onClick={this.handleSound3}> to spend</span>
              <span onClick={this.handleSound4}> another</span>
              <span onClick={this.handleSound5}> night</span>
              <span onClick={this.handleSound6}> alone.</span>
              <br />
              <span onClick={this.handleSound7}> You</span>
              <span onClick={this.handleSound8}> could</span>
              <span onClick={this.handleSound9}> call me up</span>

              <span onClick={this.handleSound28}> on the telephone.</span>
              <br />
              <span onClick={this.handleSound11}> (On the telephone)</span>
              <br />
              <span onClick={this.handleSound12}> Get</span>
              <span onClick={this.handleSound13}> doubtful</span>
              <span onClick={this.handleSound14}> of the texts.</span>
              <br />
              <span onClick={this.handleSound15}> When</span>
              <span onClick={this.handleSound16}> working</span>
              <span onClick={this.handleSound17}> so hard</span>
              <span onClick={this.handleSound18}> to impress.</span>
              <br />
              <span onClick={this.handleSound27}> (Call me up)</span>
              <br />
              <span onClick={this.handleSound20}> There is</span>
              <span onClick={this.handleSound21}> so</span>
              <span onClick={this.handleSound22}> much</span>
              <span onClick={this.handleSound23}> I'd</span>
              <span onClick={this.handleSound24}> like</span>
              <span onClick={this.handleSound25}> to say.</span>
              <br />
              <span onClick={this.handleSound26}> I get </span>
              <span onClick={this.handleSound19}> tired of</span>
              <span onClick={this.handleSound10}> the delay.</span>
            </p>
          </div>

          {/* 
          <p>
            Turns out it's what you do. That speaks of you. You can have it all.
            But you can't have me too.
          </p>
          <p>
            I will have the very prime. Of the morning gone.  Before I end my
            night. Patterns on the tiles. Look like my veins. They spread out
            wild. But the blood flows tame.
          </p>
          <p>
            I can almost see you. I can almost feel you. (But you can't) What
            you doing waiting at the door. Like it was me you were waiting for?
          </p>
          <p>
            Turns out it's what you do. That speaks of you. You can have it all.
            But you can't have me too.
          </p>
          <p>
            I can almost see you. I can almost feel you. (But you can’t) What
            you doing waiting at the door. Like it was me you were waiting for?
          </p>
          <p>
            Turns out it's what you do. That speaks of you. You can have it all.
            But you can't have me too.
          </p> */}
        </div>
        <div>
          {audioEl[0]}
          {audioEl[1]}
          {audioEl[2]}
          {audioEl[3]}
          {audioEl[4]}
          {audioEl[5]}
          {audioEl[6]}
          {audioEl[7]}
          {audioEl[8]}
          {audioEl[9]}
          {audioEl[10]}
          {audioEl[11]}
          {audioEl[12]}
          {audioEl[13]}
          {audioEl[14]}
          {audioEl[15]}
          {audioEl[16]}
          {audioEl[17]}
          {audioEl[18]}
          {audioEl[19]}
          {audioEl[20]}
          {audioEl[21]}
          {audioEl[22]}
          {audioEl[23]}
          {audioEl[24]}
          {audioEl[25]}
          {audioEl[26]}
          {audioEl[27]}
        </div>
      </>
    );
  };
}
export default Jour3;
