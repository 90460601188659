import React, { Component } from "react";
import { Link } from "react-router-dom";
import AUDIOFILE1 from "./sounds/6.1.mp3";
import AUDIOFILE2 from "./sounds/6.2.mp3";
import AUDIOFILE3 from "./sounds/6.3.mp3";
import AUDIOFILE4 from "./sounds/6.4.mp3";
import AUDIOFILE5 from "./sounds/6.5.mp3";
import AUDIOFILE6 from "./sounds/6.6.mp3";
import AUDIOFILE7 from "./sounds/6.7.mp3";
import AUDIOFILE8 from "./sounds/6.8.mp3";
import AUDIOFILE9 from "./sounds/6.9.mp3";
import AUDIOFILE10 from "./sounds/6.10.mp3";
import AUDIOFILE11 from "./sounds/6.11.mp3";
import AUDIOFILE12 from "./sounds/6.12.mp3";
import AUDIOFILE13 from "./sounds/6.13.mp3";
import AUDIOFILE14 from "./sounds/6.14.mp3";

class Jour6 extends Component {
  constructor(props) {
    super(props);
    this.result = "";
    this.result2 = "";
    this.result3 = "";
    this.result4 = "";
    this.repeat = 250;
    this.words = [];
    // this.audioEl = [];
    this.wordsObj = {
      list: [
        `I'Il faudrait maintenant fixer`,
        `l'image dans le temps comme dans l'espace,`,
        `saisir les mouvements accomplis`,
        `- se surprendre de dos enfin".`,
        `This, in the context of that book,`,
        `seems to suggest that surprising yourself from behind would permit self-understanding,`,
        `and ultimately, self-love.`,
        ` Hence the lyrics for jour 2, "If I could just surprise myself from behind,`,
        ` me and I would get along just fine".`,
        ` Hello sweet angels,Here are my thoughts on the theme of duplication for jour 2.`,
        ` Duplication is often the central element of bloodcurdling stories,`,
        ` as in, as we were saying,`,
        ` Aronofsky's Black Swan, Edgar Allen Poe's William Wilson,`,
        `etc where encountering oneself outside`,
        `of oneself acts as an element of horror and gives a startle:`,
        ` I think it would be the extreme of the weirded-out feeling we get when something appears strangely familiar.`,
        ` Duplication as a non-scary event however happens in the Spike Jonze apple commercial starring fka twigs at 2:34,`,
        ` where by dancing with herself literally,`,
        ` the character reconnects with herself.`,
        ` I love this (it's kind of the spirit of jour 2,`,
        ` the healing aspect of seeing yourself without all your self-judgement and harsh constructs about yourself) but I also love the idea of seeing yourself being initially startling...`,
        `which of course it would be, were it to happen...`,
        ` This more peaceful`,
        ` / less disruptive idea of duplication can also be encountered in Aveux non avenu,`,
        ` by Claude Cahun, who was a trans poet and photographer from the 40s,`,
        ` who deeply and deliriously questioned the notion of the self.`,
        ` I drew inspiration for the lyrics to the song from a passage I read in 2016`,
        ` (for a class called "Littérature et folie" given by a total badass young woman)`,
        ` and which had stuck with me since.`,
        ` The lyrics for jour 2 still imply eariness because of the "surprise myself from behind",`,
        ` it involves the idea of a startle,`,
        ` hence of a propension to spook.`,
        ` For the video, what if we arrived behind "ourselves"`,
        ` in the end without expecting it,`,
        ` or something along those lines,`,
        ` and were instantly afraid of what we were about to discover?`,
        ` No need to have the double even turn around...`,
        `ouri and i's acting skills would just spell it out lol.`,
        ` (This also makes me think of the scene in Harry Potter 3 where they time travel to save the hippogriff and catch a glimpse of themselves from the future and are puzzled at what they just saw... `,
        ` haha it's a great scene.) `,
        ` As we were saying, the twist could also depend on revealing that other actors who we assumed were Ouri and I actually aren’t. `,
        ` Thanks for reading my blabla. `,
        ` Would love to read yours!`,
        ` Feeling so lucky to work alongside you always,`,
        ` Helena  C'est un bijou de perfection cette pièce! `,
        ` :-D Félicitations Ouri ça torche. `,
        ` Voici toutes mes notes! `,
        ` Si t'essaies et que ça marche pas aucun problemus! `,
        ` Je me demande si ce serait cool que le kick entre à 2:12 et qu'on enlève une répétition de "Turns out it's what you do that speaks..."`,
        ` pour plus de surprise "You can have it" de 2:32 à 2:55 est un peu fort.`,
        ` les hums en arrière qu'on entend le plus à 3:19 pourraient être plus forts et même durer jusqu'à l'entrée des claps à 3:3`,
        ` Les high hats à partir de 3:22 et jusqu'à la fin sont un peu forts maybe`,
        ` Je ferais durer plus longtemps le gros beat, voir quelques répétitions sans sirène à la booty feel.`,
        ` C'est une autre approche parce qu'en ce moment ça nous laisse plus sur notre faim mais je pense que ça pourrait être cool de juste complètement tout donner...`,
        ` Quand le rhodes recommence et que la poussière retombe vers 3:50 je ne ferais pas réapparaître les "You can have it",`,
        ` ça me semble trop réminiscent du bout qui défonce tout et je pense que ce serait cool que ce soit une ouverture, dénouement, retombée qui respire plus.`,
        ` GROS BISOUS C'EST TROP BON.`,
        ` I really love the warmer ones,`,
        ` where our presence suggests a strong sorority and unity,`,
        ` a shared vulnerability.`,
        ` Not sure about the meaning of the sword ones,`,
        ` but totally in love with mud, greenhouse, "slugs", puppet, church...`,
        ` The energy of the shoot is still there and inspires me :)`,
        ` Thank you so much for bringing such a powerful team together.`,
        ` Love, Ouri.`,
        ` Do you need my help filming it? `,
        ` I’m really good with a camera in my hands…`
      ]
    };
    this.words = this.wordsObj.list;
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  callString = () => {
    this.makeString();
    this.makeString2();
    this.makeString3();
    this.makeString4();
  };
  makeString = () => {
    this.result = "";
    var wordsLength = this.words.length;

    for (var i = 0; i < this.repeat; i++) {
      this.result += this.words[Math.floor(Math.random() * wordsLength)];
    }
    this.forceUpdate();
    setTimeout(this.makeString, 250);
  };
  makeString2 = () => {
    this.result2 = "";
    var wordsLength = this.words.length;

    for (var i = 0; i < this.repeat; i++) {
      this.result2 += this.words[Math.floor(Math.random() * wordsLength)];
    }
    this.forceUpdate();
    setTimeout(this.makeString2, 250);
  };
  makeString3 = () => {
    this.result3 = "";

    var wordsLength = this.words.length;

    for (var i = 0; i < this.repeat; i++) {
      this.result3 += this.words[Math.floor(Math.random() * wordsLength)];
    }
    this.forceUpdate();
    setTimeout(this.makeString3, 250);
  };
  makeString4 = () => {
    this.result4 = "";
    var wordsLength = this.words.length;

    for (var i = 0; i < this.repeat; i++) {
      this.result4 += this.words[Math.floor(Math.random() * wordsLength)];
    }
    this.forceUpdate();
    setTimeout(this.makeString4, 250);
  };
  handleMouseMove = () => {
    console.log("hello");
    let rdm = Math.floor(Math.random() * 14) + 1;
    // console.log("rdm", rdm);
    const audio = document.getElementsByClassName("audiofile" + rdm)[0];
    console.log(audio);
    audio.play();
  };
  render = () => {
    let audioEl = [];
    audioEl[0] = (
      <audio className="audiofile1" controlsList="nodownload">
        <source src={AUDIOFILE1} type="audio/mp3"></source>
      </audio>
    );
    audioEl[1] = (
      <audio className="audiofile2">
        <source src={AUDIOFILE2} type="audio/mp3"></source>
      </audio>
    );
    audioEl[2] = (
      <audio className="audiofile3">
        <source src={AUDIOFILE3} type="audio/mp3"></source>
      </audio>
    );
    audioEl[3] = (
      <audio className="audiofile4">
        <source src={AUDIOFILE4} type="audio/mp3"></source>
      </audio>
    );
    audioEl[4] = (
      <audio className="audiofile5">
        <source src={AUDIOFILE5} type="audio/mp3"></source>
      </audio>
    );
    audioEl[5] = (
      <audio className="audiofile6">
        <source src={AUDIOFILE6} type="audio/mp3"></source>
      </audio>
    );
    audioEl[6] = (
      <audio className="audiofile7">
        <source src={AUDIOFILE7} type="audio/mp3"></source>
      </audio>
    );
    audioEl[7] = (
      <audio className="audiofile8">
        <source src={AUDIOFILE8} type="audio/mp3"></source>
      </audio>
    );
    audioEl[8] = (
      <audio className="audiofile9">
        <source src={AUDIOFILE9} type="audio/mp3"></source>
      </audio>
    );
    audioEl[9] = (
      <audio className="audiofile10">
        <source src={AUDIOFILE10} type="audio/mp3"></source>
      </audio>
    );
    audioEl[10] = (
      <audio className="audiofile11">
        <source src={AUDIOFILE11} type="audio/mp3"></source>
      </audio>
    );
    audioEl[11] = (
      <audio className="audiofile12">
        <source src={AUDIOFILE12} type="audio/mp3"></source>
      </audio>
    );
    audioEl[12] = (
      <audio className="audiofile13">
        <source src={AUDIOFILE13} type="audio/mp3"></source>
      </audio>
    );
    audioEl[13] = (
      <audio className="audiofile14">
        <source src={AUDIOFILE14} type="audio/mp3"></source>
      </audio>
    );
    //   let body = document.querySelector("body");
    //   body.style.backgroundColor = "white";
    //   body.style.fontFamily = "Helvetica Neue";
    //   body.style.fontSize = "6px";
    //   body.style.color = "grey";
    //   body.style.margin = "0px";

    //   let divPs = document.getElementById("ps");
    //   divPs.style.display = "flex";

    //   let divImg = document.getElementById("img");
    //   divImg.style.position = "absolute";
    //   divImg.style.left = "0px";
    //   divImg.style.top = "48px";

    const mouseDivStyle = {
      width: window.innerWidth / 14 / 6,
      height: "100vh"
    };
    // console.log("this outside", this);
    // let thisoutside = this;
    let i;
    let arr = [];
    for (i = 1; i < 15; i++) {
      arr.push(i);
    }
    if (window.outerWidth < 450) {
      return (
        <>
          <Link to={"/"} style={{ textDecoration: "none" }}>
            <img
              className="x"
              alt="x"
              src={require("./images/x.svg").default}
            />
          </Link>
          <div className="mouse-div-container-jour6">
            {arr.map(index => (
              <div
                className="jour6mouseDiv"
                key={index}
                style={mouseDivStyle}
                onClick={this.handleMouseMove}
              ></div>
            ))}
          </div>
          <div id="img">
            <img
              className="h"
              onLoad={this.callString}
              width="100%"
              alt="hildegard mark"
              src={require("./images/H-mobile.png").default}
            />
          </div>
          <div id="ps">
            <p id="text1">{this.result}</p>

            <p id="text2">{this.result2}</p>

            <p id="text3">{this.result3}</p>

            <p id="text4">{this.result4}</p>
          </div>
          <div>
            {audioEl[0]}
            {audioEl[1]}
            {audioEl[2]}
            {audioEl[3]}
            {audioEl[4]}
            {audioEl[5]}
            {audioEl[6]}
            {audioEl[7]}
            {audioEl[8]}
            {audioEl[9]}
            {audioEl[10]}
            {audioEl[11]}
            {audioEl[12]}
            {audioEl[13]}
            {audioEl[14]}
            {audioEl[15]}
            {audioEl[16]}
            {audioEl[17]}
            {audioEl[18]}
          </div>
        </>
      );
    } else {
      return (
        <>
          <Link to={"/"} style={{ textDecoration: "none" }}>
            <img
              className="x"
              alt="x"
              src={require("./images/x.svg").default}
            />
          </Link>
          <div className="mouse-div-container-jour6">
            {arr.map(index => (
              <div
                className="jour6mouseDiv"
                key={index}
                style={mouseDivStyle}
                onClick={this.handleMouseMove}
              ></div>
            ))}
          </div>
          <div id="img">
            <img
              onLoad={this.callString}
              width="100%"
              alt="hildegard mark"
              src={require("./images/H.png").default}
            />
          </div>
          <div id="ps">
            <p id="text1">{this.result}</p>

            <p id="text2">{this.result2}</p>

            <p id="text3">{this.result3}</p>

            <p id="text4">{this.result4}</p>
          </div>
          <div>
            {audioEl[0]}
            {audioEl[1]}
            {audioEl[2]}
            {audioEl[3]}
            {audioEl[4]}
            {audioEl[5]}
            {audioEl[6]}
            {audioEl[7]}
            {audioEl[8]}
            {audioEl[9]}
            {audioEl[10]}
            {audioEl[11]}
            {audioEl[12]}
            {audioEl[13]}
            {audioEl[14]}
            {audioEl[15]}
            {audioEl[16]}
            {audioEl[17]}
            {audioEl[18]}
          </div>
        </>
      );
    }
  };
}

export default Jour6;
