import React, { Component } from "react";
import { Link } from "react-router-dom";

class About extends Component {
  render = () => {
    return (
      <>
        <Link to={"/"} style={{ textDecoration: "none" }}>
          <img className="x" alt="x" src={require("./images/x.svg").default} />
        </Link>
        <div className="jour1-container">
          <div className="lyrics-container">
            <p className="lyrics">
              On Hildegard’s self-titled debut, eight tracks fuse together into
              a sonic sphere balancing acoustic and electronic sensitivities.
              The meeting place sounds something like dusk or dawn, and finds
              freedom in sensuality and hysteria, duality and multiplicity.
            </p>
          </div>
        </div>
      </>
    );
  };
}

export default About;
