import React, { Component } from "react";
import { Link } from "react-router-dom";

class Contact extends Component {
  render = () => {
    return (
      <>
        <Link to={"/"} style={{ textDecoration: "none" }}>
          <img className="x" alt="x" src={require("./images/x.svg").default} />
        </Link>
        <div className="jour1-container">
          <div className="lyrics-container">
            <p className="lyrics">
              Management:{" "}
              <a href="mailto:nyck@chivichivi.com">nyck@chivichivi.com</a>{" "}
              <br />
              Label:{" "}
              <a href="mailto:hello@section1.world">hello@section1.world</a>
              <br />
              Live(CAN):
              <a href="mailto:michael@heavy-trip.com">michael@heavy-trip.com</a>
              <br /> Live (UK/EU):{" "}
              <a href="mailto:will@atc-live.com">will@atc-live.com</a>
              <br />
              Press (CAN):{" "}
              <a href="mailto:kb@killbeatmusic.com">kb@killbeatmusic.com</a>
              <br /> Press (US):{" "}
              <a href="mailto:bryant@partisanrecords.com">
                bryant@partisanrecords.com
              </a>{" "}
              <br />
              Press (UK): j
              <a href="mailto:odie.penning@s-414.com">
                odie.penning@s-414.com
              </a>{" "}
              & <a href="mailto:thom.denson@s-414.com">thom.denson@s-414.com</a>
            </p>
          </div>
        </div>
      </>
    );
  };
}

export default Contact;
