import React, { Component } from "react";

class SideNote extends Component {
  render = () => {
    return (
      <>
        <div className="left-side-bar">
          <div className="third-third">
            {/* <p className="side">
              On Hildegard’s self-titled debut, eight tracks fuse together into
              a sonic sphere balancing acoustic and electronic sensitivities.
              The meeting place sounds something like dusk or dawn, and finds
              freedom in sensuality and hysteria, duality and multiplicity.
            </p> */}
            <p className="side">
              © 2021 Section1 LLC, Chivi Chivi Inc., Hildegard. 281 N. 7th St.
              #2, Brooklyn, NY 11211. All Rights Reserved. Unauthorized
              duplication is a violation of applicable laws.
            </p>
          </div>
          <div className="section1logo-container">
            <img
              className="section1"
              width="100%"
              src={require("./images/section1.png").default}
              alt="section1 logo"
            />
          </div>

          <div className="fund-container">
            <p className="fund">Funded by</p>
          </div>
          <div className="second-third">
            <img
              className="factor"
              width="100%"
              src={require("./images/factor.svg").default}
              alt="beautiful"
            />
          </div>
          <div className="first-third">
            <img
              className="canada"
              width="100%"
              src={require("./images/CanadaWordmarkBlack.svg").default}
              alt="beautifull"
            />
          </div>
        </div>
      </>
    );
  };
}

export default SideNote;
