import React, { Component } from "react";
import { Link } from "react-router-dom";
import AUDIOFILE1 from "./sounds/2.1.mp3";
import AUDIOFILE2 from "./sounds/2.2.mp3";
import AUDIOFILE3 from "./sounds/2.3.mp3";
import AUDIOFILE4 from "./sounds/2.4.mp3";
import AUDIOFILE5 from "./sounds/2.5.mp3";
import AUDIOFILE6 from "./sounds/2.6.mp3";
import AUDIOFILE7 from "./sounds/2.7.mp3";
import AUDIOFILE8 from "./sounds/2.8.mp3";
import AUDIOFILE9 from "./sounds/2.9.mp3";
import AUDIOFILE10 from "./sounds/2.10.mp3";
import AUDIOFILE11 from "./sounds/2.11.mp3";
import AUDIOFILE12 from "./sounds/2.12.mp3";
import AUDIOFILE13 from "./sounds/2.13.mp3";
import AUDIOFILE14 from "./sounds/2.14.mp3";
import AUDIOFILE15 from "./sounds/2.15.mp3";

class Jour2 extends Component {
  handleSound1 = () => {
    const audio = document.getElementsByClassName("audiofile1")[0];
    audio.play();
  };
  handleSound2 = () => {
    const audio = document.getElementsByClassName("audiofile2")[0];
    audio.play();
  };
  handleSound3 = () => {
    const audio = document.getElementsByClassName("audiofile3")[0];
    audio.play();
  };
  handleSound4 = () => {
    const audio = document.getElementsByClassName("audiofile4")[0];
    audio.play();
  };
  handleSound5 = () => {
    const audio = document.getElementsByClassName("audiofile5")[0];
    audio.play();
  };
  handleSound6 = () => {
    const audio = document.getElementsByClassName("audiofile6")[0];
    audio.play();
  };
  handleSound7 = () => {
    const audio = document.getElementsByClassName("audiofile7")[0];
    audio.play();
  };
  handleSound8 = () => {
    const audio = document.getElementsByClassName("audiofile8")[0];
    audio.play();
  };
  handleSound9 = () => {
    const audio = document.getElementsByClassName("audiofile9")[0];
    audio.play();
  };
  handleSound10 = () => {
    const audio = document.getElementsByClassName("audiofile10")[0];
    audio.play();
  };
  handleSound11 = () => {
    const audio = document.getElementsByClassName("audiofile11")[0];
    audio.play();
  };
  handleSound12 = () => {
    const audio = document.getElementsByClassName("audiofile12")[0];
    audio.play();
  };
  handleSound13 = () => {
    const audio = document.getElementsByClassName("audiofile13")[0];
    audio.play();
  };
  handleSound14 = () => {
    const audio = document.getElementsByClassName("audiofile14")[0];
    audio.play();
  };
  handleSound15 = () => {
    const audio = document.getElementsByClassName("audiofile15")[0];
    audio.play();
  };

  render = () => {
    let audioEl = [];
    audioEl[0] = (
      <audio className="audiofile1" controlsList="nodownload">
        <source src={AUDIOFILE1} type="audio/mp3"></source>
      </audio>
    );
    audioEl[1] = (
      <audio className="audiofile2">
        <source src={AUDIOFILE2} type="audio/mp3"></source>
      </audio>
    );
    audioEl[2] = (
      <audio className="audiofile3">
        <source src={AUDIOFILE3} type="audio/mp3"></source>
      </audio>
    );
    audioEl[3] = (
      <audio className="audiofile4">
        <source src={AUDIOFILE4} type="audio/mp3"></source>
      </audio>
    );
    audioEl[4] = (
      <audio className="audiofile5">
        <source src={AUDIOFILE5} type="audio/mp3"></source>
      </audio>
    );
    audioEl[5] = (
      <audio className="audiofile6">
        <source src={AUDIOFILE6} type="audio/mp3"></source>
      </audio>
    );
    audioEl[6] = (
      <audio className="audiofile7">
        <source src={AUDIOFILE7} type="audio/mp3"></source>
      </audio>
    );
    audioEl[7] = (
      <audio className="audiofile8">
        <source src={AUDIOFILE8} type="audio/mp3"></source>
      </audio>
    );
    audioEl[8] = (
      <audio className="audiofile9">
        <source src={AUDIOFILE9} type="audio/mp3"></source>
      </audio>
    );
    audioEl[9] = (
      <audio className="audiofile10">
        <source src={AUDIOFILE10} type="audio/mp3"></source>
      </audio>
    );
    audioEl[10] = (
      <audio className="audiofile11">
        <source src={AUDIOFILE11} type="audio/mp3"></source>
      </audio>
    );
    audioEl[11] = (
      <audio className="audiofile12">
        <source src={AUDIOFILE12} type="audio/mp3"></source>
      </audio>
    );
    audioEl[12] = (
      <audio className="audiofile13">
        <source src={AUDIOFILE13} type="audio/mp3"></source>
      </audio>
    );
    audioEl[13] = (
      <audio className="audiofile14">
        <source src={AUDIOFILE14} type="audio/mp3"></source>
      </audio>
    );
    audioEl[14] = (
      <audio className="audiofile15">
        <source src={AUDIOFILE15} type="audio/mp3"></source>
      </audio>
    );

    return (
      <>
        <Link to={"/"} style={{ textDecoration: "none" }}>
          <img className="x" alt="x" src={require("./images/x.svg").default} />
        </Link>

        <div className="jour1-container">
          <div className="lyrics-container">
            <p className="lyrics">
              <span onClick={this.handleSound1}>Fill in blanks.</span>
              <br />
              <span onClick={this.handleSound2}> No point</span>
              <span onClick={this.handleSound3}> in lying.</span>
              <br />
              <span onClick={this.handleSound4}> Reading my mind.</span>
              <br />
              <span onClick={this.handleSound5}> Never lonely.</span>
              <br />
              <span onClick={this.handleSound6}> Treat myself</span>
              <span onClick={this.handleSound7}> real nice.</span>
              <br />
              <span onClick={this.handleSound8}> Kiss my forehead.</span>
              <br />
              <span onClick={this.handleSound9}> Tucked in </span>
              <span onClick={this.handleSound10}>sleep tight.</span> <br />
              <span onClick={this.handleSound11}> Leave the house </span>
              <span onClick={this.handleSound12}>at dusk.</span>
              <br />
              <span onClick={this.handleSound13}> Go</span>
              <span onClick={this.handleSound14}> walking</span>
              <span onClick={this.handleSound15}> all night.</span> <br />
              <span onClick={this.handleSound1}> Haven't</span>
              <span onClick={this.handleSound2}> gotten lost.</span> <br />
              <span onClick={this.handleSound3}> In such</span>
              <span onClick={this.handleSound4}> a long time.</span> <br />
              <br />
              <span onClick={this.handleSound5}>If I could</span>
              <span onClick={this.handleSound6}> just surprise myself</span>
              <span onClick={this.handleSound7}> from behind.</span> <br />
              <span onClick={this.handleSound8}> Me and I</span>
              <span onClick={this.handleSound9}> would get</span>
              <span onClick={this.handleSound10}> along just fine.</span>
            </p>
          </div>

          {/* 
          <p>
            Turns out it's what you do. That speaks of you. You can have it all.
            But you can't have me too.
          </p>
          <p>
            I will have the very prime. Of the morning gone.  Before I end my
            night. Patterns on the tiles. Look like my veins. They spread out
            wild. But the blood flows tame.
          </p>
          <p>
            I can almost see you. I can almost feel you. (But you can't) What
            you doing waiting at the door. Like it was me you were waiting for?
          </p>
          <p>
            Turns out it's what you do. That speaks of you. You can have it all.
            But you can't have me too.
          </p>
          <p>
            I can almost see you. I can almost feel you. (But you can’t) What
            you doing waiting at the door. Like it was me you were waiting for?
          </p>
          <p>
            Turns out it's what you do. That speaks of you. You can have it all.
            But you can't have me too.
          </p> */}
        </div>
        <div>
          {audioEl[0]}
          {audioEl[1]}
          {audioEl[2]}
          {audioEl[3]}
          {audioEl[4]}
          {audioEl[5]}
          {audioEl[6]}
          {audioEl[7]}
          {audioEl[8]}
          {audioEl[9]}
          {audioEl[10]}
          {audioEl[11]}
          {audioEl[12]}
          {audioEl[13]}
          {audioEl[14]}
        </div>
      </>
    );
  };
}
export default Jour2;
