import React, { Component } from "react";
import { Link } from "react-router-dom";
import AUDIOFILE1 from "./sounds/4.1.mp3";
import AUDIOFILE2 from "./sounds/4.2.mp3";
import AUDIOFILE3 from "./sounds/4.3.mp3";
import AUDIOFILE4 from "./sounds/4.4.mp3";
import AUDIOFILE5 from "./sounds/4.5.mp3";
import AUDIOFILE6 from "./sounds/4.6.mp3";
import AUDIOFILE7 from "./sounds/4.7.mp3";
import AUDIOFILE8 from "./sounds/4.8.mp3";
import AUDIOFILE9 from "./sounds/4.9.mp3";
import AUDIOFILE10 from "./sounds/4.10.mp3";
import AUDIOFILE11 from "./sounds/4.11.mp3";
import AUDIOFILE12 from "./sounds/4.12.mp3";
import AUDIOFILE13 from "./sounds/4.13.mp3";
import AUDIOFILE14 from "./sounds/4.14.mp3";
import AUDIOFILE15 from "./sounds/4.15.mp3";
import AUDIOFILE16 from "./sounds/4.16.mp3";
import AUDIOFILE17 from "./sounds/4.17.mp3";

class Jour4 extends Component {
  handleSound1 = () => {
    const audio = document.getElementsByClassName("audiofile1")[0];
    audio.play();
  };
  handleSound2 = () => {
    const audio = document.getElementsByClassName("audiofile2")[0];
    audio.play();
  };
  handleSound3 = () => {
    const audio = document.getElementsByClassName("audiofile3")[0];
    audio.play();
  };
  handleSound4 = () => {
    const audio = document.getElementsByClassName("audiofile4")[0];
    audio.play();
  };
  handleSound5 = () => {
    const audio = document.getElementsByClassName("audiofile5")[0];
    audio.play();
  };
  handleSound6 = () => {
    const audio = document.getElementsByClassName("audiofile6")[0];
    audio.play();
  };
  handleSound7 = () => {
    const audio = document.getElementsByClassName("audiofile7")[0];
    audio.play();
  };
  handleSound8 = () => {
    const audio = document.getElementsByClassName("audiofile8")[0];
    audio.play();
  };
  handleSound9 = () => {
    const audio = document.getElementsByClassName("audiofile9")[0];
    audio.play();
  };
  handleSound10 = () => {
    const audio = document.getElementsByClassName("audiofile10")[0];
    audio.play();
  };
  handleSound11 = () => {
    const audio = document.getElementsByClassName("audiofile11")[0];
    audio.play();
  };
  handleSound12 = () => {
    const audio = document.getElementsByClassName("audiofile12")[0];
    audio.play();
  };
  handleSound13 = () => {
    const audio = document.getElementsByClassName("audiofile13")[0];
    audio.play();
  };
  handleSound14 = () => {
    const audio = document.getElementsByClassName("audiofile14")[0];
    audio.play();
  };
  handleSound15 = () => {
    const audio = document.getElementsByClassName("audiofile15")[0];
    audio.play();
  };
  handleSound16 = () => {
    const audio = document.getElementsByClassName("audiofile16")[0];
    audio.play();
  };
  handleSound17 = () => {
    const audio = document.getElementsByClassName("audiofile17")[0];
    audio.play();
  };

  render = () => {
    let audioEl = [];
    audioEl[0] = (
      <audio className="audiofile1" controlsList="nodownload">
        <source src={AUDIOFILE1} type="audio/mp3"></source>
      </audio>
    );
    audioEl[1] = (
      <audio className="audiofile2">
        <source src={AUDIOFILE2} type="audio/mp3"></source>
      </audio>
    );
    audioEl[2] = (
      <audio className="audiofile3">
        <source src={AUDIOFILE3} type="audio/mp3"></source>
      </audio>
    );
    audioEl[3] = (
      <audio className="audiofile4">
        <source src={AUDIOFILE4} type="audio/mp3"></source>
      </audio>
    );
    audioEl[4] = (
      <audio className="audiofile5">
        <source src={AUDIOFILE5} type="audio/mp3"></source>
      </audio>
    );
    audioEl[5] = (
      <audio className="audiofile6">
        <source src={AUDIOFILE6} type="audio/mp3"></source>
      </audio>
    );
    audioEl[6] = (
      <audio className="audiofile7">
        <source src={AUDIOFILE7} type="audio/mp3"></source>
      </audio>
    );
    audioEl[7] = (
      <audio className="audiofile8">
        <source src={AUDIOFILE8} type="audio/mp3"></source>
      </audio>
    );
    audioEl[8] = (
      <audio className="audiofile9">
        <source src={AUDIOFILE9} type="audio/mp3"></source>
      </audio>
    );
    audioEl[9] = (
      <audio className="audiofile10">
        <source src={AUDIOFILE10} type="audio/mp3"></source>
      </audio>
    );
    audioEl[10] = (
      <audio className="audiofile11">
        <source src={AUDIOFILE11} type="audio/mp3"></source>
      </audio>
    );
    audioEl[11] = (
      <audio className="audiofile12">
        <source src={AUDIOFILE12} type="audio/mp3"></source>
      </audio>
    );
    audioEl[12] = (
      <audio className="audiofile13">
        <source src={AUDIOFILE13} type="audio/mp3"></source>
      </audio>
    );
    audioEl[13] = (
      <audio className="audiofile14">
        <source src={AUDIOFILE14} type="audio/mp3"></source>
      </audio>
    );
    audioEl[14] = (
      <audio className="audiofile15">
        <source src={AUDIOFILE15} type="audio/mp3"></source>
      </audio>
    );
    audioEl[15] = (
      <audio className="audiofile16">
        <source src={AUDIOFILE16} type="audio/mp3"></source>
      </audio>
    );
    audioEl[16] = (
      <audio className="audiofile17">
        <source src={AUDIOFILE17} type="audio/mp3"></source>
      </audio>
    );

    return (
      <>
        <Link to={"/"} style={{ textDecoration: "none" }}>
          <img className="x" alt="x" src={require("./images/x.svg").default} />
        </Link>

        <div className="jour1-container">
          <div className="lyrics-container">
            <p className="lyrics">
              <span onClick={this.handleSound1}>Quickly</span>
              <span onClick={this.handleSound2}> walks on by.</span>
              <br />
              <span onClick={this.handleSound3}> Left them high.</span>
              <br />
              <span onClick={this.handleSound4}> Quickly</span>
              <span onClick={this.handleSound5}> walks on by.</span>
              <br />
              <span onClick={this.handleSound6}> High and dry.</span>
              <br />
              <span onClick={this.handleSound7}> Quickly</span>
              <span onClick={this.handleSound8}> walks on by.</span>
              <br />
              <span onClick={this.handleSound9}> Left them high.</span>
              <br />
              <span onClick={this.handleSound10}> Quickly</span>
              <span onClick={this.handleSound11}> walks on by.</span>
              <br />
              <span onClick={this.handleSound12}> High and dry.</span>
              <br />
              <span onClick={this.handleSound13}> I guess</span>
              <span onClick={this.handleSound14}> she’d</span>
              <span onClick={this.handleSound15}> do it.</span>
              <br />
              <span onClick={this.handleSound16}> I guess</span>
              <span onClick={this.handleSound17}> she’d</span>
              <span onClick={this.handleSound1}> do it.</span>
              <br />
              <span onClick={this.handleSound2}> Yes she</span>
              <span onClick={this.handleSound3}> would yes</span>
              <span onClick={this.handleSound4}> she would.</span>
              <br />
              <span onClick={this.handleSound5}> Yes she would.</span>
            </p>
          </div>

          {/* 
          <p>
            Turns out it's what you do. That speaks of you. You can have it all.
            But you can't have me too.
          </p>
          <p>
            I will have the very prime. Of the morning gone.  Before I end my
            night. Patterns on the tiles. Look like my veins. They spread out
            wild. But the blood flows tame.
          </p>
          <p>
            I can almost see you. I can almost feel you. (But you can't) What
            you doing waiting at the door. Like it was me you were waiting for?
          </p>
          <p>
            Turns out it's what you do. That speaks of you. You can have it all.
            But you can't have me too.
          </p>
          <p>
            I can almost see you. I can almost feel you. (But you can’t) What
            you doing waiting at the door. Like it was me you were waiting for?
          </p>
          <p>
            Turns out it's what you do. That speaks of you. You can have it all.
            But you can't have me too.
          </p> */}
        </div>
        <div>
          {audioEl[0]}
          {audioEl[1]}
          {audioEl[2]}
          {audioEl[3]}
          {audioEl[4]}
          {audioEl[5]}
          {audioEl[6]}
          {audioEl[7]}
          {audioEl[8]}
          {audioEl[9]}
          {audioEl[10]}
          {audioEl[11]}
          {audioEl[12]}
          {audioEl[13]}
          {audioEl[14]}
          {audioEl[15]}
          {audioEl[16]}
        </div>
      </>
    );
  };
}
export default Jour4;
