import React, { Component } from "react";
import { Route, BrowserRouter } from "react-router-dom";
import HomeScreen from "./HomeScreen.jsx";
import SideNote from "./SideNote.jsx";
import HomeScreenAL from "./HomeScreenAL.jsx";
import Jour1 from "./Jour1.jsx";
import Jour2 from "./Jour2.jsx";
import Jour3 from "./Jour3.jsx";
import Jour4 from "./Jour4.jsx";
import Jour5 from "./Jour5.jsx";
import Jour6 from "./Jour6.jsx";
import Jour7 from "./Jour7.jsx";
import Jour8 from "./Jour8.jsx";
import About from "./About.jsx";
import Contact from "./Contact.jsx";
import Tour from "./Tour.jsx";

class App extends Component {
  renderHomeScreen = () => {
    return <HomeScreen />;
  };
  renderSideNote = () => {
    return (
      <>
        <div className="side-home-container">
          <SideNote />
          <HomeScreenAL />
        </div>
      </>
    );
  };
  renderJour1 = () => {
    return (
      <>
        <Jour1 />
      </>
    );
  };
  renderJour2 = () => {
    return (
      <>
        <Jour2 />
      </>
    );
  };
  renderJour3 = () => {
    return (
      <>
        <Jour3 />
      </>
    );
  };
  renderJour4 = () => {
    return (
      <>
        <Jour4 />
      </>
    );
  };
  renderJour5 = () => {
    return (
      <>
        <Jour5 />
      </>
    );
  };
  renderJour6 = () => {
    return (
      <>
        <Jour6 />
      </>
    );
  };
  renderJour7 = () => {
    return (
      <>
        <Jour7 />
      </>
    );
  };
  renderJour8 = () => {
    return (
      <>
        <Jour8 />
      </>
    );
  };
  renderAbout = () => {
    return (
      <>
        <About />
      </>
    );
  };
  renderContact = () => {
    return (
      <>
        <Contact />
      </>
    );
  };
  renderTour = () => {
    return (
      <>
        <Tour />
      </>
    );
  };

  render = () => {
    return (
      <BrowserRouter>
        <Route exact={true} path="/" render={this.renderHomeScreen} />
        <Route exact={true} path="/side-note/" render={this.renderSideNote} />
        <Route exact={true} path="/jour1/" render={this.renderJour1} />
        <Route exact={true} path="/jour2/" render={this.renderJour2} />
        <Route exact={true} path="/jour3/" render={this.renderJour3} />
        <Route exact={true} path="/jour4/" render={this.renderJour4} />
        <Route exact={true} path="/jour5/" render={this.renderJour5} />
        <Route exact={true} path="/jour6/" render={this.renderJour6} />
        <Route exact={true} path="/jour7/" render={this.renderJour7} />
        <Route exact={true} path="/jour8/" render={this.renderJour8} />
        <Route exact={true} path="/about/" render={this.renderAbout} />
        <Route exact={true} path="/contact/" render={this.renderContact} />
        <Route exact={true} path="/tour/" render={this.renderTour} />
      </BrowserRouter>
    );
  };
}

export default App;
