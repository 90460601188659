import React, { Component } from "react";
import { Link } from "react-router-dom";

class Tour extends Component {
  render = () => {
    return (
      <>
        <Link to={"/"} style={{ textDecoration: "none" }}>
          <img className="x" alt="x" src={require("./images/x.svg").default} />
        </Link>

        <div className="jour1-container">
          <div className="lyrics-container">
            <p className="lyrics">Tour dates coming soon</p>
          </div>
        </div>
      </>
    );
  };
}
export default Tour;
