import React, { Component } from "react";
import { Link } from "react-router-dom";

class HomeScreenAL extends Component {
  constructor(props) {
    super(props);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    // this.state = { x: 0, y: 0 };
    this.indexarray = [];
    this.numberImgs = 113;
    let i;
    for (i = 0; i < this.numberImgs; i++) {
      this.indexarray.push(i);
    }

    this.indexObj = { list: this.indexarray };
    // console.log(this.indexObj);
    this.indexarray = this.indexObj.list;
    // console.log("thisindex", this.indexarray);
    this.index = 0;
    this.imgLink = "1.jpg";
  }

  handleMouseMove = () => {
    this.index = Math.floor(Math.random() * this.indexarray.length);
    // console.log(this.index);
    this.forceUpdate();
  };
  handleMobile = () => {
    this.index = Math.floor(Math.random() * this.indexarray.length);
    // console.log(this.index);
    this.forceUpdate();
  };
  render = () => {
    function importAll(r) {
      let images = {};
      r.keys().map(item => {
        images[item.replace("./", "")] = r(item);
        return images;
      });
      return images;
    }
    // console.log("hello");
    let images = importAll(require.context("./images", false, /\.jpg/));
    // console.log("images", images);
    let imageskeys = Object.keys(images);
    this.imgLink = imageskeys[this.index];
    // console.log("values", Object.keys(images));
    // console.log("imglink", this.imgLink);
    // console.log(window.innerWidth / this.indexarray.length);
    const mouseDivStyle = {
      width: window.innerWidth / this.indexarray.length,
      height: "80vh"
    };

    if (window.innerWidth < "450px") {
      return (
        <>
          <div className="fold-container-al">
            <div className="firstfold">
              <div className="header">
                <Link
                  className="pointer"
                  to={"/jour1/"}
                  style={{ textDecoration: "none" }}
                >
                  <button className="jours">
                    (Special edition)
                    <br /> Jour 1
                  </button>
                </Link>
                <Link
                  className="pointer"
                  to={"/jour2/"}
                  style={{ textDecoration: "none" }}
                >
                  <button className="jours">
                    (Special edition)
                    <br /> Jour 2
                  </button>
                </Link>
                <Link
                  className="pointer"
                  to={"/jour3/"}
                  style={{ textDecoration: "none" }}
                >
                  <button className="jours">
                    (Special edition)
                    <br /> Jour 3
                  </button>
                </Link>
                <Link
                  className="pointer"
                  to={"/jour4/"}
                  style={{ textDecoration: "none" }}
                >
                  <button className="jours">
                    (Special edition)
                    <br /> Jour 4
                  </button>
                </Link>
              </div>
            </div>

            <div className="centerfold">
              <div className="sidebar-container">
                <div className="sidebar">
                  <Link to={"/"} style={{ textDecoration: "none" }}>
                    <button className="arrow-right">
                      <i className="arrow left"></i>
                    </button>
                  </Link>
                  <Link to={"/about/"} style={{ textDecoration: "none" }}>
                    <p>About</p>
                  </Link>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://hildegard.lnk.to/hildegard"
                  >
                    <p>Album</p>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://hildegard.lnk.to/apple"
                  >
                    <p>Apple Music</p>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://hildegard.lnk.to/youtube"
                  >
                    <p>Youtube</p>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://hildegard.lnk.to/spotify"
                  >
                    <p>Spotify</p>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://hildegard.lnk.to/facebook"
                  >
                    <p>Facebook</p>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://hildegard.lnk.to/instagram"
                  >
                    <p>Instagram</p>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://hildegard.lnk.to/twitter"
                  >
                    <p>Twitter</p>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://hildegard.lnk.to/tiktok"
                  >
                    <p>TikTok</p>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://gmail.us1.list-manage.com/subscribe?u=42c667136cddddfdad0c4aaed&id=3eeb427f6e"
                  >
                    <p>Mailing List</p>
                  </a>
                  <Link to={"/contact/"} style={{ textDecoration: "none" }}>
                    <p>Contact</p>
                  </Link>
                  <Link to={"/"} style={{ textDecoration: "none" }}>
                    <button className="arrow-right">
                      <i className="arrow left"></i>
                    </button>
                  </Link>
                </div>
              </div>
              {/* <div className="logotype-container">
              <img
                className="logotype"
                alt="logotype"
                src={require("./images/HLDGD.svg").default}
              />
            </div> */}
              <div className="viewport-container">
                <div className="viewport">
                  <div className="rdm-img-container">
                    <img
                      onLoad={this.handleMobile}
                      className="random-imgs"
                      src={require("./images/" + this.imgLink).default}
                      alt="beautiful"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="lastfold">
              <div className="footer">
                <Link
                  className="pointer"
                  to={"/jour5/"}
                  style={{ textDecoration: "none" }}
                >
                  <button className="jours">
                    (Special edition)
                    <br /> Jour 5
                  </button>
                </Link>
                <Link
                  className="pointer"
                  to={"/jour6/"}
                  style={{ textDecoration: "none" }}
                >
                  <button className="jours">
                    (Special edition)
                    <br /> Jour 6
                  </button>
                </Link>
                <Link
                  className="pointer"
                  to={"/jour7/"}
                  style={{ textDecoration: "none" }}
                >
                  <button className="jours">
                    (Special edition)
                    <br /> Jour 7
                  </button>
                </Link>
                <Link
                  className="pointer"
                  to={"/jour8/"}
                  style={{ textDecoration: "none" }}
                >
                  <button className="jours">
                    (Special edition)
                    <br /> Jour 8
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="fold-container-al">
            <div className="firstfold">
              <div className="header">
                <Link
                  className="pointer"
                  to={"/jour1/"}
                  style={{ textDecoration: "none" }}
                >
                  <button className="jours">
                    (Special edition)
                    <br /> Jour 1
                  </button>
                </Link>
                <Link
                  className="pointer"
                  to={"/jour2/"}
                  style={{ textDecoration: "none" }}
                >
                  <button className="jours">
                    (Special edition)
                    <br /> Jour 2
                  </button>
                </Link>
                <Link
                  className="pointer"
                  to={"/jour3/"}
                  style={{ textDecoration: "none" }}
                >
                  <button className="jours">
                    (Special edition)
                    <br /> Jour 3
                  </button>
                </Link>
                <Link
                  className="pointer"
                  to={"/jour4/"}
                  style={{ textDecoration: "none" }}
                >
                  <button className="jours">
                    (Special edition)
                    <br /> Jour 4
                  </button>
                </Link>
              </div>
            </div>

            <div className="centerfold">
              <div className="sidebar-container">
                <div className="sidebar">
                  <Link to={"/"} style={{ textDecoration: "none" }}>
                    <button className="arrow-right">
                      <i className="arrow left"></i>
                    </button>
                  </Link>
                  <Link to={"/about/"} style={{ textDecoration: "none" }}>
                    <p>About</p>
                  </Link>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://hildegard.lnk.to/hildegard"
                  >
                    <p>Album</p>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://hildegard.lnk.to/apple"
                  >
                    <p>Apple Music</p>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://hildegard.lnk.to/youtube"
                  >
                    <p>Youtube</p>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://hildegard.lnk.to/spotify"
                  >
                    <p>Spotify</p>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://hildegard.lnk.to/facebook"
                  >
                    <p>Facebook</p>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://hildegard.lnk.to/instagram"
                  >
                    <p>Instagram</p>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://hildegard.lnk.to/twitter"
                  >
                    <p>Twitter</p>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://hildegard.lnk.to/tiktok"
                  >
                    <p>TikTok</p>
                  </a>
                  <Link to={"/tour/"} style={{ textDecoration: "none" }}>
                    <p>Tour</p>
                  </Link>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://store.section1.world/hildegard"
                  >
                    <p>Store</p>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://gmail.us1.list-manage.com/subscribe?u=42c667136cddddfdad0c4aaed&id=3eeb427f6e"
                  >
                    <p>Mailing List</p>
                  </a>
                  <Link to={"/contact/"} style={{ textDecoration: "none" }}>
                    <p>Contact</p>
                  </Link>
                  <Link to={"/"} style={{ textDecoration: "none" }}>
                    <button className="arrow-right">
                      <i className="arrow left"></i>
                    </button>
                  </Link>
                </div>
              </div>
              {/* <div className="logotype-container">
              <img
                className="logotype"
                alt="logotype"
                src={require("./images/HLDGD.svg").default}
              />
            </div> */}
              <div className="viewport-container">
                <div className="mouse-div-container">
                  {this.indexarray.map(item => (
                    <div
                      key={item}
                      style={mouseDivStyle}
                      className={"mouse-div" + item}
                      onMouseOver={this.handleMouseMove}
                    ></div>
                  ))}
                </div>
                <div className="viewport">
                  <div className="rdm-img-container">
                    <img
                      className="random-imgs"
                      src={require("./images/" + this.imgLink).default}
                      alt="beautiful"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="lastfold">
              <div className="footer">
                <Link
                  className="pointer"
                  to={"/jour5/"}
                  style={{ textDecoration: "none" }}
                >
                  <button className="jours">
                    (Special edition)
                    <br /> Jour 5
                  </button>
                </Link>
                <Link
                  className="pointer"
                  to={"/jour6/"}
                  style={{ textDecoration: "none" }}
                >
                  <button className="jours">
                    (Special edition)
                    <br /> Jour 6
                  </button>
                </Link>
                <Link
                  className="pointer"
                  to={"/jour7/"}
                  style={{ textDecoration: "none" }}
                >
                  <button className="jours">
                    (Special edition)
                    <br /> Jour 7
                  </button>
                </Link>
                <Link
                  className="pointer"
                  to={"/jour8/"}
                  style={{ textDecoration: "none" }}
                >
                  <button className="jours">
                    (Special edition)
                    <br /> Jour 8
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </>
      );
    }
  };
}

export default HomeScreenAL;
